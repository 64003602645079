import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { supabase } from './supabaseClient';

const root = createRoot(document.getElementById('root'));

try {
    root.render(
      <React.StrictMode>
        <SessionContextProvider supabaseClient={supabase}>
          <App />
        </SessionContextProvider>
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Error rendering app:', error);
    root.render(<div>An error occurred. Please check the console and verify your environment setup.</div>);
  }