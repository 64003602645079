import React, { useState } from 'react'
import { supabase } from './supabaseClient'
import Google_logo from './Google_logo.webp' 

export default function Auth({ setSubscriptionStatus, onSignIn }) {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSignUp, setIsSignUp] = useState(false)

  const apiUrl = process.env.NODE_ENV === 'production'
              ? process.env.REACT_APP_PROD_CONTENT_SERVICE_URL
              : process.env.REACT_APP_API_URL;

  const handleAuth = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const { data, error } = isSignUp 
        ? await supabase.auth.signUp({ email, password })
        : await supabase.auth.signInWithPassword({ email, password })
      
      if (error) {
        console.error('Auth error:', error)
        if (error.message === 'Email not confirmed') {
          alert("Your email is not confirmed yet. Please check your inbox and spam folder for the verification email.")
        } else if (error.message === 'User already registered') {
          alert("An account with this email already exists. Please sign in or use a different email.")
        } else {
          alert(error.message)
        }
      } else if (data && data.user) {
        console.log('Auth successful:', data)
        if (isSignUp) {
          if (data.user.identities && data.user.identities.length === 0) {
            alert("An account with this email already exists. Please sign in or reset your password if you've forgotten it.")
          } else {
            // Create Stripe customer
            const response = await fetch(`${apiUrl}/create-stripe-customer`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ userId: data.user.id, email: data.user.email })
            });
           await response.json();
            // Store Stripe customer ID in Supabase
            await supabase
            
            alert("Sign-up successful! Please check your email for verification.");
          }
        } else {
          // Check subscription status
          const { data: subscriptionData, error: subscriptionError } = await supabase
            .from('subscriptions')
            .select('status')
            .eq('user_id', data.user.id)
            .single();
          if (subscriptionError) {
            console.error('Subscription check error:', subscriptionError);
            setSubscriptionStatus('inactive');
          } else {
            setSubscriptionStatus(subscriptionData?.status || 'inactive');
            onSignIn();
          }
        }
      }
    } catch (error) {
      console.error('Unexpected error:', error)
      alert('An unexpected error occurred. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });
      
      if (error) throw error;
      
      // The user will be redirected to Google for authentication,
      // so we don't need to do anything else here.
    } catch (error) {
      console.error('Error signing in with Google:', error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <h2>{isSignUp ? 'Sign Up' : 'Sign In'}</h2>
      <form onSubmit={handleAuth}>
        <input
          type="email"
          placeholder="Your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="button-9" disabled={loading}>
          {loading ? 'Loading...' : (isSignUp ? 'Sign Up' : 'Sign In')}
        </button>
      </form>
      <button onClick={() => setIsSignUp(!isSignUp)} className="toggle-auth">
        {isSignUp ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}
      </button>
      <button onClick={handleGoogleSignIn} className="google-sign-in-button">
        <img src={Google_logo} alt="Google logo" className="google-logo" />
        <span>Continue with Google</span>
      </button>
    </div>
  )
}