import { createClient } from '@supabase/supabase-js';

console.log('NODE_ENV:', process.env.NODE_ENV);

const supabaseUrl = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_PROD_SUPABASE_URL 
  : process.env.REACT_APP_SUPABASE_URL;

const supabaseAnonKey = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_PROD_SUPABASE_ANON_KEY
  : process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Missing Supabase configuration. URL:', supabaseUrl, 'Anon Key:', supabaseAnonKey);
  throw new Error('supabaseUrl and supabaseAnonKey are required.');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);